import ClickWheel from "clickwheel-js";
import React from "react";
import "./App.css";

function App() {
  const [email, setEmail] = React.useState("");
  const [sent, setSent] = React.useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    await fetch(
      "https://app.loops.so/api/newsletter-form/clpsl4mk5008ml20ozvjha4wy",
      {
        method: "POST",
        body: `userGroup=clickwheeljs.com&email=${encodeURIComponent(email)}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    setSent(true);
    setEmail("");
  };

  return (
    <div className="App">
      <div id="body">
        <ClickWheel
          className="click-wheel-container"
          scrollerId="body"
        />
        <div className="details">
          Made with &hearts; by{" "}
          <a
            href="https://ae.studio?utm_source=sds&utm_medium=referral&utm_campaign=clickwheeljs&utm_content=logo&utm_term=3ff5251a-e107-4d47-bfb8-b2962debd252"
            target="_blank"
            rel="noreferrer"
          >
            AE Studio
          </a>
          <br />
          <br />
          View on{" "}
          <a
            href="https://github.com/agencyenterprise/clickwheel-js"
            target="_blank"
            rel="noreferrer"
          >
            GitHub
          </a>
          <br />
          <br />
          View on{" "}
          <a
            href="https://www.npmjs.com/package/clickwheel-js"
            target="_blank"
            rel="noreferrer"
          >
            NPM
          </a>
        </div>

        <section className="hero">
          <h1 className="h1">
            Cursors and touch screens are inefficient tools to navigate the web.
            <br />
            We built ClickWheel.js, to bring the web into a more civilized age.{" "}
          </h1>

          <h2 className="h2">Scroll with the click wheel to give it a try.</h2>
          <h2 className="h2">
            Remember this is an iPod click wheel. Click and scroll around in a
            circle just like on an iPod
          </h2>
          <img
            src={"arrow.png"}
            className="arrow-img"
          />
        </section>

        <section className="sectionText">
          <h1 className="h1">
            Throughout human history, we&#39;ve invented new ways to navigate.
          </h1>

          <h1 className="h1">
            We are working on making it smoother, just like the{" "}
            <a
              href="https://www.ipodsocial.com/"
              target="_blank"
              rel="noreferrer"
            >
              Ipod Social
            </a>{" "}
            wheel.
            <br />
            <br />
            {!sent ? (
              <form onSubmit={onSubmit}>
                <input
                  type="email"
                  required
                  placeholder="my@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit"> Get Notified </button>
              </form>
            ) : (
              "We'll let you know when it's ready!"
            )}
          </h1>
        </section>

        <section className="backgroundImageMap">
          <h3>Maps</h3>
        </section>

        <section className="backgroundImageComp">
          <h3>Compasses</h3>
        </section>

        <section className="backgroundImageAstro">
          <h3>Astrolabes</h3>
        </section>

        <section className="backgroundImageSext">
          <h3>Sextants</h3>
        </section>

        <section className="sectionText">
          <h1 className="h1">
            And humanity didn&#39;t stop there.
            <br />
            Even today we have GPS, and that annoying backseat driver.
          </h1>

          <h1 className="h1">
            But navigation peaked in February 2004 with Apple&#39;s introduction
            of the Click Wheel on the iPod Mini.
          </h1>
          <h4 className="h4">
            Although it appears to have been invented by Norihiko Saito in 1998.
          </h4>

          <img
            className="ipod"
            src="./images/ipod.png"
            alt-text="iPod Nano 1st Gen"
          ></img>

          <h1 className="h1">
            To celebrate the peak of navigation, we&#39;ve created
            clickWheel.js.
            <br />
            <br />
            Explore the internet the way it was meant to be explored– by wheel.
          </h1>
        </section>
        <div className="last-section"></div>
      </div>
    </div>
  );
}

export default App;
